<template>
    <Box class="w-full">
        <template v-slot:header>
            <div class="flex items-center w-16">
                <i v-if="data.icon" class="mdi mr-2" :class="'mdi-' +  data.icon"></i>
                <span class="truncate">{{ data.name }}</span>
            </div>
        </template>
        <template v-slot:content>
            <div class="w-full flex justify-between items-center">
                <div v-if="datatypes[index].datatype == 'percent'" class="md-value font-medium">{{ data.value | numFormat('0,0.0a') }}<small>%</small></div>
                <div v-else-if="datatypes[index].datatype == 'currency'" class="md-value font-medium">{{ data.value | numFormat('0,0.0a') }}<small>€</small></div>
                <div v-else class="md-value font-medium">{{ data.value | numFormat('0,0a') }}</div>
                <div
                    class="sm-value font-medium"
                    :class="{
                        'text-good' : status == true,
                        'text-bad' :  status == false,
                    }">{{ data.percent | numFormat('0.0') }}%</div>
            </div>
        </template>¡
    </Box>
</template>

<script>
    import Box from '@/components/Box.vue';
    export default {
        name: 'NetworkKpi',
        components: {
            Box,
        },
        props: ['data','index','datatypes'],
        data() {
            return {
                status: true,                
            }
        }
    }
</script>