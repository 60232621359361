<template>
    <div class="w-full flex justify-start md:justify-between items-center mb-4 md:mb-0">
        <div class="w-2/12">
            <img :src="'img/icons/' + data.toLowerCase() + '.svg'" class="w-auto h-12" alt="" />
        </div>

        <div class="w-4/12 flex flex-col flex-1">
            <div class="lg-title">{{ data.name }}</div>
            <div class="w-full flex flex-col justify-start">
                <div class="flex items-center">
                    <i class="mdi mdi-calendar text-casika sm-title mr-1"></i>
                    <div class="xs-title mr-6">
                        <slot name="period"></slot>
                    </div>
                </div>
                <div class="flex items-center">
                    <i class="mdi mdi-map-marker text-casika sm-title mr-1"></i>
                    <div class="xs-title mr-6">
                        <slot name="market"></slot>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden md:flex w-6/12 justify-end">
            <ButtonTabs :createdTabs="tabs" @selectedTab="selectedTab" />
        </div>
    </div>
</template>

<script>
    import ButtonTabs from '@/components/elements/ButtonTabs.vue';
    export default {
        name: 'NetworkHeader',
        components: {
            ButtonTabs,
        },
        props: ['data'],
        data() {
            return {
                tabs: [
                    {
                        id: 0,
                        tab: 'Campañas',
                    },
                    {
                        id: 1,
                        tab: 'Grupos',
                    },
                    {
                        id: 2,
                        tab: 'Anuncios',
                    },
                ],
            };
        },
        methods: {
            selectedTab(tab) {
                this.$emit('selectedTab', tab);
            },
        },
    };
</script>
