<template>
    <div class="hidden tv:flex absolute right-0 bottom-0 z-50 mr-3 mb-3">
        <!-- <div class="bg-module rounded-full w-16 h-16 f-center shadow-xl">
            <i class="mdi mdi-filter-variant text-gray1 text-2xl"></i>
        </div>
 -->

        <div id="circularMenu2" class="circular-menu circular-menu">

            <a class="floating-btn f-center" onclick="document.getElementById('circularMenu2').classList.toggle('active');">
                <i class="mdi mdi-menu text-gray1 text-2xl"></i>
                
            </a>

            <menu class="items-wrapper">
                <div @click="openModal('filter-date')" class="menu-item mdi mdi-calendar"></div>
                <div @click="openModal('filter-market')" class="menu-item mdi mdi-map"></div>
                <div @click="routerTo('admin')" class="menu-item mdi mdi-cog"></div>
                <div @click="routerTo('logout')" class="menu-item mdi mdi-logout"></div>
            </menu>

        </div>


    </div>
</template>

<script>
    export default {
        name: 'HeaderTv',
        props: ['modal'],
        methods: {
            openModal(filter) {
                this.$emit('openModal',filter);
            },
            routerTo(route) {
                this.$router.push({ path: `/user/${route}` }).catch(() => ({}))
            }
        }
    }
</script>

<style>
body {
  background-color: #EDEDED;
}
.circular-menu {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.circular-menu .floating-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  background-color: #F6B188;
  box-shadow: 0 2px 5px 0 hsla(0, 0%, 0%, .26);  
  color: white;
  text-align: center;
  cursor: pointer;
  outline: 0;
}

.circular-menu.active .floating-btn {
  box-shadow: inset 0 0 3px hsla(0, 0%, 0%, .3);
}

.circular-menu .floating-btn:active {
  box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, .4);
}

.circular-menu .floating-btn i {
  font-size: 1.75rem;
  transition: transform .2s; 
  
  color: white;
}

.circular-menu.active .floating-btn i {
  /* transform: rotate(-45deg); */
}

.circular-menu:after {
  display: block;
  content: ' ';
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  background-color: #F6B188;
  transition: all .3s ease;
}

.circular-menu.active:after {
  transform: scale3d(5.5, 5.5, 1);
  transition-timing-function: cubic-bezier(.68, 1.55, .265, 1);
}

.circular-menu .items-wrapper {
  padding: 0;
  margin: 0;
}

.circular-menu .menu-item {
  position: absolute;
  top: .2em;
  right: .2em;
  z-index: -1;
  display: block;
  text-decoration: none;
  color: #202837;
  font-size: 1em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  text-align: center;
  line-height: 3;
  cursor: pointer;
  background-color: hsla(0,0%,0%,.1);
  transition: transform .3s ease, background .2s ease;
}

.circular-menu .menu-item:hover {
  background-color: hsla(0,0%,0%,.3);
}

.circular-menu.active .menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.circular-menu.active .menu-item:nth-child(1) {
  transform: translate3d(1em,-7em,0);
}

.circular-menu.active .menu-item:nth-child(2) {
  transform: translate3d(-3.5em,-6.3em,0);
}

.circular-menu.active .menu-item:nth-child(3) {
  transform: translate3d(-6.5em,-3.2em,0);
}

.circular-menu.active .menu-item:nth-child(4) {
  transform: translate3d(-7em,1em,0);
}
</style>