<template>
    <div class="detail w-full p-2 cursor-pointer" :class="containerClass" @click="openKpis">
        <div class="w-full" :class="{ flex: add_type === 2 }">
            <div v-if="add_type === 2" class="w-16 h-16 rounded-lg overflow-hidden">
                <img
                    class="w-full h-full object-cover"
                    :src="data.image.length ? data.image : 'img/default.jpg'"
                    alt=""
                    @error="setAltImg($event)"
                />
            </div>
            <div class="w-auto flex-1 ml-1">
                <slot name="header"></slot>
                <slot name="data"></slot>
                <transition name="fade">
                    <template v-if="kpis">
                        <slot name="kpis"></slot>
                    </template>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { setAltImg } from '@/utils/helpers';

    export default {
        name: 'NetworkAdd',
        props: {
            add_type: {
                type: Number,
                required: true,
            },
            data: {
                type: [Array, Object],
                required: true,
            },
            containerClass: {
                type: String,
                required: false,
                default: 'mb-3',
            },
        },
        data() {
            return {
                kpis: false,
            };
        },
        methods: {
            setAltImg,
            openKpis() {
                this.kpis = !this.kpis;
            },
        },
    };
</script>
