<template>
    <div class="w-full flex flex-col tb:flex-row items-center marketingGeneral">
        <div class="w-full tb:w-1/2">
            <div class="w-full flex justify-between -mx-1 flex-wrap">

                <div
                    v-for="(item, index) in data.general"
                    :key="item.id"
                    class="px-1 flex-1 mb-1 cursor-pointer"
                    @click="setEvolutive(item.id, item.name, item.evolution)"
                >
                    <Box
                        class="h-full border-2 transition transition-all"
                        :class="item.name === evolution_name[evolutive_kpi] ? 'border-blue shadow-xl' : 'border-module'"
                    >
                        <template v-slot:header>
                            <div class="flex flex-col leading-none">
                                <i class="mdi text-3xl" :class="'mdi-' + main_kpis[index].icon"></i>
                                {{ item.name }}
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="flex h-full">
                                <div class="flex flex-col mt-5 h-full flex-1">
                                    <div class="lg-value font-bold">
                                        {{ item.value | numFormat('0,0.0a') }}
                                        <span v-if="item.name !== 'Transacciones'">€</span>
                                    </div>
                                    <div
                                        class="md-value font-medium"
                                        :class="{
                                            'text-good': item.growth >= 0,
                                            'text-bad': item.growth < 0,
                                        }"
                                    >
                                        <span v-if="item.growth > 0">+</span>{{ item.growth | numFormat('0,0.0') }}%
                                    </div>
                                </div>
                                <div
                                    v-if="item.name !== 'Transacciones' && item.name !== 'Inversión'"
                                    class="flex flex-col flex-1 w-56 h-24 -mt-5"
                                >
                                    <Gauge
                                        :gaugeOptions="gaugeOptions"
                                        :chartId="gaugeOptions.id"
                                        :colorSuccessPane="gaugeOptions.paneColor"
                                        :colorWarningsPane="gaugeOptions.paneColor"
                                        :primary="item.percent"
                                        :primary_name="'% respecto objetivo'"
                                        :gradientGauge="false"
                                    >
                                        <template>
                                            <span class="text-xs text-gray1">Anterior</span>
                                            <div class="sm-value font-medium">
                                                {{ item.previous | numFormat('0,0a') }} €
                                            </div>
                                        </template>
                                    </Gauge>
                                </div>
                            </div>
                        </template>
                    </Box>
                </div>
            </div>
        </div>
        <div class="w-full tb:w-1/2 flex flex-col tb:flex-row items-center">
            <div class="w-full tb:w-8/12 mx-2">
                <div class="flex justify-between items-center">
                    <span v-if="evolution_name" class="md-title text-blue mt-1">
                        {{ evolution_name[evolutive_kpi] }}
                    </span>
                    <div class="flex">
                        <Legend v-for="item in evolutive_chart_legends" :key="item.id" :colorIcon="item.color">
                            <template v-slot:legend>
                                {{ item.name }}
                            </template>
                        </Legend>
                    </div>
                </div>
                <div class="w-full h-40 extra:h-64 tv:h-40">
                    <AreaSpline
                        :datatype="chartKpi === 'Transacciones' ? '' : '€'"
                        :key="evolutive_kpi"
                        :chartId="'chartId'"
                        :data="data.general[evolutive_kpi].evolution"
                        :xDate="'%d %B %Y'"
                    />
                </div>
            </div>
            <div class="w-full tb:w-4/12 flex flex-wrap grid grid-cols-2 gap-2">
                <Box v-for="(kpi, index) in data.more_kpis" :key="kpi.id" class="w-full">
                    <template v-slot:header>
                        {{ kpi.name }}
                    </template>
                    <template v-slot:content>
                        <div class="w-full flex justify-between items-center">
                            <div class="md-value font-medium" v-if="kpi.id === 6">- -</div>
                            <div class="md-value font-medium" v-else>
                                {{ getKPIValueSign(kpi, index).value | numFormat(getKPIValueSign(kpi, index).format) }}
                                {{ getKPIValueSign(kpi, index).sign }}
                            </div>
                            <div class="sm-value font-medium" v-if="kpi.id === 6">- -</div>
                            <div
                                v-if="kpi.id !== 6"
                                class="sm-value font-medium"
                                :class="{
                                    'text-good': kpi.growth >= 0,
                                    'text-bad': kpi.growth < 0,
                                }"
                            >
                                <span v-if="kpi.growth > 0 && kpi.id !== 6">+</span
                                >{{ kpi.growth | numFormat('0,0.0a') }}%
                            </div>
                        </div>
                    </template>
                </Box>
            </div>
        </div>
    </div>
</template>

<script>
    import { actions, state } from '@/store';

    import MarketingMainKpi from '@/components/MarketingMainKpi.vue';
    import Box from '@/components/Box.vue';
    import Gauge from '@/components/charts/Gauge.vue';
    import AreaSpline from '@/components/charts/AreaSpline.vue';
    import Legend from '@/components/elements/Legend.vue';

    export default {
        name: 'MarketingGeneral',
        components: {
            MarketingMainKpi,
            Box,
            Gauge,
            AreaSpline,
            Legend,
        },
        props: {
            data: {
                type: [Array, Object],
                required: true,
                default: () => ({}),
            },
        },
        data() {
            return {
                interval: null,
                evolutive_kpi: 0,
                status: false,
                areaSplineKey: 0,
                evolutive: [],
                chartKpi: '',
                gaugeOptions: {
                    id: 'gauge',
                    y: 24,
                    classname: 'z-30 relative',
                    innerSize: 80,
                    bgColor: '#2E384A',
                    paneColor: '#F6B188',
                },
                main_kpis: [
                    {
                        id: 0,
                        name: 'Ingresos',
                        value: 234,
                        percent: 34,
                        previous_value: 34,
                        icon: 'cash-check',
                    },
                    {
                        id: 1,
                        name: 'ROAS',
                        value: 234,
                        percent: 34,
                        previous_value: 34,
                        icon: 'cash-refund',
                    },
                    {
                        id: 2,
                        name: 'Transacciones',
                        value: 234,
                        percent: 34,
                        previous_value: null,
                        icon: 'arrow-left-right-bold',
                    },
                    {
                        id: 3,
                        name: 'Inversión',
                        value: 234,
                        percent: 34,
                        previous_value: null,
                        icon: 'cash-multiple',
                    },
                ],
                secondary_kpis: [
                    {
                        id: 0,
                        name: 'Alcance',
                        value: 234,
                        percent: 34,
                        datatype: 'absolute',
                    },
                    {
                        id: 1,
                        name: 'Sesiones',
                        value: 234,
                        percent: 34,
                        datatype: 'absolute',
                    },
                    {
                        id: 2,
                        name: 'Tasa rebote',
                        value: 234,
                        percent: 34,
                        datatype: 'percent',
                    },
                    {
                        id: 3,
                        name: 'CPC medio',
                        value: 234,
                        percent: 34,
                        datatype: 'currency',
                    },
                    {
                        id: 4,
                        name: 'Ticket medio',
                        value: 234,
                        percent: 34,
                        datatype: 'currency',
                    },
                    {
                        id: 5,
                        name: '% conversión',
                        value: 234,
                        percent: 34,
                        datatype: 'percent',
                    },
                ],
                evolutive_chart_legends: [
                    {
                        id: 0,
                        name: 'Actual',
                        color: '#5D76DD',
                    },
                    {
                        id: 1,
                        name: 'Anterior',
                        color: '#596575',
                    },
                ],
            };
        },
        computed: {
            marketing_evolutive() {
                return state.marketing_evolutive;
            },
            evolution_name() {
                return this.data.general.map(kpi => (
                    kpi.name
                ))
            }
        },
        methods: {
            setEvolutive(id, item, evolution) {
                console.log('this.interval :>> ', this.interval);
                clearInterval(this.interval);
                actions.setMarketingEvolutive(item);
                this.evolutive_kpi = id;
                this.evolutive = evolution;
                this.chartKpi = item;
            },
            getKPIValueSign(kpi, index) {
                let value = kpi.value;
                let sign = '';
                let nulled = false;
                let format = '0,0.0a';

                if (kpi.id !== 6) {
                    if (this.secondary_kpis[index].datatype === 'percent') {
                        sign = '%';
                    } else if (this.secondary_kpis[index].datatype === 'currency') {
                        sign = '€';
                    } else if (kpi.value < 1000) {
                        format = '0,0';
                    }
                } else {
                    nulled = true;
                    value = '- -';
                    format = '';
                }

                return {
                    value,
                    sign,
                    nulled,
                    format,
                };
                /*
                <div v-if="secondary_kpis[index].datatype === 'percent'" class="md-value font-medium">{{ kpi.value | numFormat('0,0.0a') }} %</div>
                            <div v-else-if="kpi.id === 6" class="md-value font-medium"> -- </div>
                            <div v-else-if="secondary_kpis[index].datatype === 'currency'" class="md-value font-medium">{{ kpi.value | numFormat('0,0.0a') }} €</div>
                            <div v-else-if="kpi.value < 1000" class="md-value font-medium">{{ kpi.value | numFormat('0,0') }}</div>
                            <div v-else class="md-value font-medium">{{ kpi.value | numFormat('0,0.0a') }}</div>
                * */
            },
            autoEvolutive() {                
                this.interval = setInterval(() => {
                    if(this.evolutive_kpi < 3) {
                        this.evolutive_kpi++;
                    } else {
                        this.evolutive_kpi = 0;
                    }      
                    
                }, 20000 ); 
            },
        },
        watch: {
            evolutive() {
                this.areaSplineKey += 1;
            },
        },
        created() {            
            this.evolutive = this.data.general[0].evolution;
        },
        mounted() {
            this.autoEvolutive();
        },
        destroyed() {
            clearInterval(this.interval);
        },
    };
</script>
<style>
    @media (min-width: 768px) {
        .marketingGeneral {
            min-height: 18vh;
        }
    }
</style>
