<template>
    <div class="w-full flex">
        <div class="w-5/12 h-40">
            <span class="text-white">Kpi Aquí</span>
            <Gauge
                :gaugeOptions="gaugeOptions"
                :colorSuccessPane="gaugeOptions.paneColor"
                :chartId="gaugeOptions.id"
                :primary="54"
                :primary_name="'% respecto objetivo'"
                :gradientGauge="true" >
                <i
                    class="mdi text-xl text-gray1"></i>
                <span class="sm-title">Nombre</span>
            </Gauge>
        </div>
        
    </div>
</template>

<script>
    import Gauge from '@/components/charts/Gauge.vue';
    export default {
        name: 'MarketingMainKpi',
        components: {
            Gauge,
        },
        data() {
            return {                
                gaugeOptions: {
                    id: 'gauge',
                    y: 24,
                    classname: 'z-30 relative',
                    innerSize: 80,
                    bgColor: '#2E384A',
                    paneColor: '#F6B188'
                },
            }
        }
    }
</script>