<template>
    <div class="w-full mb-3">
        <div class="header-module flex flex-col md:flex-row tb:flex-row md:justify-between">
            <div class="w-auto tb:w-3/12 flex flex-row items-center py-2 md:py-0">
                <a href="/api/admin">
                    <img class="h-10 lg:mr-6 mr-1" src="img/logo.png" alt="" />
                </a>
                <div v-if="!tvMode" class="flex lg:w-16 w-12 ml-2 mr-1 relative -top-2 z-50">
                    <nav class="menu">
                        <input type="checkbox" class="menu-open" name="menu-open" id="menu-open" />

                        <label class="menu-open-button" for="menu-open" @click="toggleMenu">
                            <span class="hamburger hamburger-1"></span>
                            <span class="hamburger hamburger-2"></span>
                            <span class="hamburger hamburger-3"></span>
                        </label>

                        <router-link
                            v-if="user && user.roles.includes('sales')"
                            :to="{ path: 'ventas' }"
                            class="menu-item"
                            :content="'Ventas'"
                            v-tippy="{ followCursor: true, arrow: false }"
                        >
                            <i class="mdi mdi-cash-register"></i>
                        </router-link>
                        <router-link
                            v-if="user && user.roles.includes('products')"
                            :to="{ path: 'productos' }"
                            class="menu-item"
                            :content="'Productos'"
                            v-tippy="{ followCursor: true, arrow: false }"
                        >
                            <i class="mdi mdi-table-furniture"></i>
                        </router-link>
                        <router-link
                            v-if="user && user.roles.includes('logistic')"
                            :to="{ path: 'logistica' }"
                            class="menu-item"
                            :content="'Logística'"
                            v-tippy="{ followCursor: true, arrow: false }"
                            ><i class="mdi mdi-warehouse"></i>
                        </router-link>
                        <router-link
                            v-if="user && user.roles.includes('marketing')"
                            :to="{ path: 'marketing' }"
                            class="menu-item"
                            :content="'Marketing'"
                            v-tippy="{ followCursor: true, arrow: false }"
                            ><i class="mdi mdi-account-multiple"></i>
                        </router-link>
                    </nav>
                </div>

                <h1 v-if="!openedMenu" class="text-2xl lg:text-3xl font-medium text-casika z-50 inline-block capitalize">
                    {{ currentRouteName }}
                </h1>
                <ButtonSecondary
                    class="block md:hidden absolute right-4"
                    :content="'Salir'"
                    v-tippy="{ followCursor: true, arrow: false }"
                    @buttonAction="logout"
                >
                    <template v-slot:icon>
                        <i class="mdi mdi-logout-variant text-lg"></i>
                    </template>
                </ButtonSecondary>
            </div>

            <!-- {{selectedDate}} - {{market}} -->

            <!-- <template v-if="marketingView">
              <div class="hidden lg:flex w-full tb:w-4/12 items-center">
                <div class="w-3/12 text-casika">En la web hoy: </div>
                <div class="flex flex-1">
                    <div class="bg-casika-opacity-33 rounded-full px-2 flex justify-between items-center">
                      <span class="text-casika text-xl mdi mdi-application"></span>
                      <span class="tb-value text-white font-medium ml-2">1.908</span>
                    </div>
                    <div class="flex justify-between items-center">
                      <div class="w-8 h-line mx-1 bg-gray2"></div>
                      <div class="px-1 text-gray2 font-medium">58<small>%</small></div>
                      <div class="w-8 h-line mx-1 bg-gray2"></div>
                    </div>
                    <div class="bg-casika-opacity-33 rounded-full px-2 flex justify-between items-center">
                      <span class="text-casika text-xl mdi mdi-cart"></span>
                      <span class="tb-value text-white font-medium ml-2">1.908</span>
                    </div>
                    <div class="flex justify-between items-center">
                      <div class="w-8 h-line mx-1 bg-gray2"></div>
                      <div class="px-1 text-gray2 font-medium">58<small>%</small></div>
                      <div class="w-8 h-line mx-1 bg-gray2"></div>
                    </div>
                    <div class="bg-casika-opacity-33 rounded-full px-2 flex justify-between items-center">
                      <span class="text-casika text-xl mdi mdi-checkbox-marked-circle"></span>
                      <span class="tb-value text-white font-medium ml-2">1.908</span>
                    </div>
                </div>
              </div>
            </template> -->

            <div
                class="
                    w-auto
                    tb:w-6/12
                    lg:w-5/12
                    flex
                    filter
                    flex-col
                    sm:flex-row
                    lg:justify-end
                    justify-end
                    tb:justify-end
                    items-center
                "
            >
                <div v-if="!tvMode && !isHomePage && !logisticView" class="w-full md:w-40 lg:w-64 mx-0 md:mx-4 flex items-center">
                    <span
                        class="mdi mdi-calendar-blank text-2xl text-gray2 mr-1 lg:inline-block md:hidden inline-block"
                    ></span>
                    <VueCtkDateTimePicker
                        v-model="computedSelectedDate"
                        :range="true"
                        :dark="true"
                        :auto-close="true"
                        :no-shortcuts="true"
                        :no-header="true"
                        :no-value-to-custom-elem="true"
                        :only-date="true"
                        :no-button="true"
                        :position="'bottom'"
                        :label="'Seleccionar fechas'"
                        :noClearButton="true"
                        formatted="DD-MMM-YY"
                        button-color="#F6B18A"
                        color="#F6B18A"
                    />
                </div>
                <template v-if="!logisticView">
                    <div v-if="!tvMode && !isHomePage" class="w-full md:w-32 mx-0 md:mx-2 flex items-center">                    
                        <span
                            class="
                                mdi mdi-map-marker-outline
                                text-2xl text-gray2
                                mr-1
                                lg:inline-block
                                md:hidden
                                inline-block
                            "
                        ></span>
                
                        <All
                            action="Request"
                            model="ApiRequest"
                            :immediate="true"
                            :sensitive="true"
                            :data.sync="countries"
                            :config="{ url: 'countries' }"
                        >
                        </All>

                        <dropdown
                            v-if="countries"
                            :close-on-click="true"
                            class-name="select-dropdown"
                            class="md:w-32 w-full"
                        >
                            <template slot="btn">
                                <span v-if="selectedMarket.name" class="text-tiny">{{ selectedMarket.name }}</span>
                                <span v-else class="text-tiny">Todos</span>
                            </template>

                            <template slot="body">
                                <div
                                    v-for="country in countries.data"
                                    :key="country.id"
                                    class="flex items-center mb-2 py-1 text-tiny"
                                    @click="filterMarket(country)"
                                >
                                    <div
                                        class="w-1 h-6 mr-2"
                                        :class="{
                                            'bg-casika': selectedMarket.id == country.id,
                                            'bg-gray2': selectedMarket.id != country.id,
                                        }"
                                    ></div>
                                    <span
                                        :class="{
                                            'text-casika': selectedMarket.id == country.id,
                                            'text-gray2': selectedMarket.id != country.id,
                                        }"
                                        >{{ country.name }}</span
                                    >
                                </div>
                            </template>
                        </dropdown>                    
                    </div>
                </template>
                <div v-if="!tvMode" class="w-full md:w-auto flex items-center mx-2">
                    <Button
                        v-if="!isHomePage"
                        @buttonAction="sendFilters"
                        class="py-3 w-full md:w-9/12 md:w-auto flex justify-center"
                    >
                        <template v-slot:icon>
                            <i class="mdi mdi-reload text-lg"></i>
                        </template>
                        Filtrar
                    </Button>
                    <!-- <ButtonSecondary @buttonAction="deleteFilters">
                        <template v-slot:icon>
                          <i class="mdi mdi-reload text-lg"></i>
                        </template>
                    </ButtonSecondary> -->
                    <ButtonSecondary
                        class="md:block hidden"
                        :content="'Salir'"
                        v-tippy="{ followCursor: true, arrow: false }"
                        @buttonAction="logout"
                    >
                        <template v-slot:icon>
                            <i class="mdi mdi-logout-variant text-lg"></i>
                        </template>
                    </ButtonSecondary>
                </div>
                <!-- <toggle-button class="hidden xl:flex">
                  <toggle-button
                    v-model="modeScreen"
                    @change="setTvMode"
                    :value="false"
                    :labels="{checked: 'TV', unchecked: 'PC'}"
                    :color="{checked: '#d2895d', unchecked: '#0aaba0', disabled: '#CCCCCC'}"
                    :switch-color="{checked: '#F6B188', unchecked: '#13F2E3', disabled: '#CCCCCC'}"  />
                </toggle-button> -->
            </div>
        </div>

        <!-- <template v-if="marketingView">
          <div class="flex lg:hidden flex-col sm:flex-row module w-full mt-3 justify-between items-center">
            <div class="w-full sm:w-1/3 text-casika mb-2 sm:mb-0">En la web hoy: </div>
            <div class="flex flex-1 justify-end">
                <div class="bg-casika-opacity-33 w-16 sm:w-auto h-16 sm:h-auto rounded-full px-2 py-0 sm:py-1 flex flex-col sm:flex-row justify-center sm:justify-between items-center">
                  <span class="text-casika leading-none text-xl mdi mdi-application"></span>
                  <span class="tb-value text-white font-medium ml-0 sm:ml-2">1.908</span>
                </div>
                <div class="flex justify-between items-center">
                  <div class="w-3 sm:w-10 tb:w-24 h-line mx-1 bg-gray2"></div>
                  <div class="px-0 sm:px-1 text-gray2 font-medium">58<small>%</small></div>
                  <div class="w-3 sm:w-10 tb:w-24 h-line mx-1 bg-gray2"></div>
                </div>
                <div class="bg-casika-opacity-33 w-16 sm:w-auto h-16 sm:h-auto rounded-full px-2 py-0 sm:py-1 flex flex-col sm:flex-row justify-center sm:justify-between items-center">
                  <span class="text-casika leading-none text-xl mdi mdi-cart"></span>
                  <span class="tb-value text-white font-medium ml-0 sm:ml-2">1.908</span>
                </div>
                <div class="flex justify-between items-center">
                  <div class="w-3 sm:w-10 tb:w-24 h-line mx-1 bg-gray2"></div>
                  <div class="px-0 sm:px-1 text-gray2 font-medium">58<small>%</small></div>
                  <div class="w-3 sm:w-10 tb:w-24 h-line mx-1 bg-gray2"></div>
                </div>
                <div class="bg-casika-opacity-33 w-16 sm:w-auto h-16 sm:h-auto rounded-full px-2 py-0 sm:py-1 flex flex-col sm:flex-row justify-center sm:justify-between items-center">
                  <span class="text-casika leading-none text-xl mdi mdi-checkbox-marked-circle"></span>
                  <span class="tb-value text-white font-medium ml-0 sm:ml-2">1.908</span>
                </div>
            </div>
          </div>
        </template> -->
    </div>
</template>

<script>
    import { All } from '@/api/components';
    import { actions, state } from '@/store';
    import Dropdown from 'bp-vuejs-dropdown';
    import Button from '@/components/elements/Button.vue';
    import ButtonSecondary from '@/components/elements/ButtonSecondary.vue';
    import { ProjectViews } from '@/api/utils';
    import { Auth } from '@/api/models';
    import routes from '@/router/routes';
    import EventBus from '@/utils/event-bus';

    export default {
        name: 'HeaderProject',
        components: {
            All,
            Dropdown,
            Button,
            ButtonSecondary,
        },
        data() {
            return {
                modeScreen: false,
                openedMenu: false,
                countries: [],
                selectedDate: {},
                selectedMarket: {
                    id: null,
                    name: '',
                },
            };
        },
        computed: {
            user() {
                return state.user;
            },
            isHomePage() {
                return this.$route.name === 'Home';
            },
            tvMode() {
                return state.tvMode;
            },
            period() {
                return state.period;
            },
            market() {
                return state.market;
            },
            logisticView() {
                return this.$route.name === 'Logística';
            },
            marketingView() {
                return this.$route.name === 'Marketing';
            },
            currentRouteName() {
                return this.$route.name;
            },
            computedSelectedDate: {
                get() {
                    return this.selectedDate;
                },
                set(v) {
                    this.selectedDate = v;
                },
                //return {
                //    start: this.$moment(this.period.from).format('YYYY-MM-DD'),
                //    end: this.$moment(this.period.to).format('YYYY-MM-DD')
                //}
            },
        },
        methods: {
            sendFilters() {
                actions.setPeriod({
                    from: this.$moment(this.selectedDate.start, this.$moment.defaultFormat).toDate(),
                    to: this.$moment(this.selectedDate.end, this.$moment.defaultFormat).toDate(),
                });
                actions.setMarket(this.selectedMarket);
                if (this.$route.name === 'Products') {
                    this.$refs.ProductsQuery.request();
                }
                EventBus.$emit('filtered');
                //console.log(state.period)
            },
            filterMarket(market) {
                this.selectedMarket = market;
            },
            deleteFilters() {
                this.selectedMarket = '';
                this.selectedDate = null;
                actions.setPeriod({
                    from: null,
                    to: null,
                });
            },
            toggleMenu() {
                this.openedMenu = !this.openedMenu;
            },
            setTvMode(tvMode) {
                actions.setTvMode(tvMode.value);
            },
            async logout() {
                await Auth.logout();
                await actions.resetAuth();
                this.$router.push({ name: 'Login' }).catch(() => ({}));
            },
        },
        created() {
            this.selectedMarket = this.market;
            this.selectedDate = {
                start: this.$moment(state.period.from).format('YYYY-MM-DD'),
                end: this.$moment(state.period.to).format('YYYY-MM-DD'),
            };
            //console.log(state.period)
        },
    };
</script>


<style>


    .menu-item,
    .menu-open-button {
        background: #f6b188;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        margin-left: -40px;
        position: absolute;
        top: 12px;
        color: #202837;
        text-align: center;
        line-height: 80px;
        transform: translate3d(0, 0, 0);
        transition: transform ease-out 200ms;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
    }

    .menu-open {
        display: none;
    }

    .hamburger {
        width: 20px;
        height: 2px;
        background: white;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10.5px;
        margin-top: -1.5px;
        transition: transform 200ms;
    }

    .hamburger-1 {
        transform: translate3d(0, -6px, 0);
    }

    .hamburger-2 {
        transform: translate3d(0, 0, 0);
    }

    .hamburger-3 {
        transform: translate3d(0, 6px, 0);
    }

    .menu-open:checked + .menu-open-button .hamburger-1 {
        transform: translate3d(0, 0, 0) rotate(45deg);
    }

    .menu-open:checked + .menu-open-button .hamburger-2 {
        transform: translate3d(0, 0, 0) scale(0.1, 1);
    }

    .menu-open:checked + .menu-open-button .hamburger-3 {
        transform: translate3d(0, 0, 0) rotate(-45deg);
    }

    .menu {
        margin-left: 20px;
        padding-top: 0;
        padding-left: 20px;
        height: 50px;
        box-sizing: border-box;
        font-size: 16px;
        text-align: left;
    }

    .menu-item:hover {
        background: white;
        color: #f6b188;
    }

    .menu-item:nth-child(3) {
        transition-duration: 180ms;
    }

    .menu-item:nth-child(4) {
        transition-duration: 180ms;
    }

    .menu-item:nth-child(5) {
        transition-duration: 180ms;
    }

    .menu-item:nth-child(6) {
        transition-duration: 180ms;
    }

    .menu-open-button {
        z-index: 2;
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-duration: 400ms;
        transform: scale(1.1, 1.1) translate3d(0, 0, 0);
        cursor: pointer;
    }

    .menu-open-button:hover {
        transform: scale(1.2, 1.2) translate3d(0, 0, 0);
    }

    .menu-open:checked + .menu-open-button {
        transition-timing-function: linear;
        transition-duration: 200ms;
        transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    }

    .menu-open:checked ~ .menu-item {
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .menu-open:checked ~ .menu-item:nth-child(3) {
        transition-duration: 170ms;
        transform: translate3d(80px, 0, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(4) {
        transition-duration: 250ms;
        transform: translate3d(160px, 0, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(5) {
        transition-duration: 330ms;
        transform: translate3d(240px, 0, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(6) {
        transition-duration: 410ms;
        transform: translate3d(320px, 0, 0);
    }

    @media (max-width: 475px) {
        .menu-item,
        .menu-open-button {
            width: 40px;
            height: 40px;
        }

        .menu-open:checked ~ .menu-item:nth-child(3) {
            transition-duration: 170ms;
            transform: translate3d(50px, 0, 0);
        }

        .menu-open:checked ~ .menu-item:nth-child(4) {
            transition-duration: 250ms;
            transform: translate3d(100px, 0, 0);
        }

        .menu-open:checked ~ .menu-item:nth-child(5) {
            transition-duration: 330ms;
            transform: translate3d(150px, 0, 0);
        }

        .menu-open:checked ~ .menu-item:nth-child(6) {
            transition-duration: 410ms;
            transform: translate3d(200px, 0, 0);
        }
    }

    .custom-button.is-dark {
        background-color: #79849a !important;
    }

    .datetimepicker.is-dark .datepicker,
    .datetimepicker.is-dark .pickers-container,
    .year-month-selector.dark {
        background: #253343 !important;
    }

    .datepicker-container.is-dark .datepicker-today {
        background-color: rgb(93, 118, 221) !important;
    }

    .field.is-dark .field-input {
        background-color: #253343 !important;
        color: hsla(0, 0%, 100%, 0.7);
        border-radius: 40px;
        border: 2px solid #1e232e !important;
    }

    .bp-dropdown__body.select-dropdown-bp__body {
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 4px;
        padding: 5px 10px;
        background-color: #253343;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        z-index: 9999;
        color: #a0aec0;
        cursor: pointer;

        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }

    .bp-dropdown__btn.select-dropdown-bp__btn {
        background-color: #253343 !important;
        color: hsla(0, 0%, 100%, 0.7);
        border-radius: 40px;
        border: 2px solid #1e232e !important;
        padding: 0.5rem 1rem;
        width: 100%;
        height: 40px;
    }

    .bp-dropdown__btn {
        justify-content: space-between;
    }

    .month-picker__container {
        z-index: 9999;

    }
    .month-picker__container .month-picker__year,
    .month-picker__container .month-picker,
    .month-picker__month {
        background-color: #253343 !important;
        color: #596575;
        cursor: pointer;
    }
    .month-picker__month {
        border: 1px solid rgba(245, 245, 245, .05)
    }
    .month-picker__month.selected {
        color: #F6B188;
        border: 2px solid #F6B188;
    }
    .month-picker-input{
        border-radius: 50px !important;
        background-color: #253343;
        color: #ffffffb3;
        padding: 0.65em 1em !important;
        border: 2px solid #1e232e !important;
        width: 100%;
        cursor: pointer !important;
    }
    .month-picker-input-container {
        width: 100% !important;
    }
    .month-picker__year {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .month-picker__year p {
        color: white;
    }
    .month-picker__year button {
        background-color: transparent;
        border: none;
    }
    .select-date .mdi,
    .select-market .mdi {
        transition: color .4s ease;
    }
    .select-date:hover .mdi,
    .select-market:hover .mdi {
        color: #F6B188;
    }
</style>
