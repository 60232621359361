<template>
    <div class="w-full flex flex-col items-center">
        <All
            action="Request"
            model="ApiRequest"
            :immediate="true"
            :sensitive="true"
            :query="{
                from: period.from,
                to: period.to,
                country: marketId,
                platform: network.id,
            }"
            v-slot="{ loading }"
            :data.sync="data"
            :config="{ url: network.platform }"
        >
            <Loading v-if="loading"></Loading>

            <div v-else class="w-full h-full">

                <NetworkHeader :data="network.id" @selectedTab="selectedTab">
                    <template v-slot:period>
                        {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                        {{ period.to | moment('DD MMM YY') }}
                    </template>
                    <template v-slot:market>
                        {{ market }}
                    </template>
                </NetworkHeader>

                <template v-if="data.data">
                    <div class="w-full grid grid-cols-2 tb:grid-cols-4 gap-2 my-2">
                        <NetworkKpi
                            v-for="(item, index) in data.data.general"
                            :key="item.id"
                            :data="item"
                            :index="index"
                            :datatypes="general_datatypes"
                        />
                    </div>

                    <transition name="fade">
                        <div v-show="secondary" class="w-full grid grid-cols-2 md:grid-cols-3 gap-2">
                            <NetworkKpi
                                v-for="(item, index) in data.data.more_kpi"
                                :key="item.id"
                                :data="item"
                                :index="index"
                                :datatypes="secondary_datatypes"
                            />
                        </div>
                    </transition>

                    <ButtonSecondary @buttonAction="buttonAction" class="w-24 flex justify-center mx-auto">
                        <span v-if="secondary">Menos Kpis</span>
                        <span v-else>Más Kpis</span>
                    </ButtonSecondary>

                    <div class="flex md:hidden w-full md:w-7/12 justify-center">
                        <ButtonTabs :createdTabs="tabs" @selectedTab="selectedTab" />
                    </div>

                    <div
                        v-if="data.data.groups || data.data.advertisements"
                        class="w-full"
                        style="max-height: 580px; overflow: auto"
                    >
                        <NetworkAdd
                            v-for="(element, index) in getNetwork"
                            :key="element.id"
                            :add_type="add_type"
                            :data="element"
                            :containerClass="index < getNetwork.length - 1 ? 'mb-3' : ''"
                        >
                            <template v-slot:header>
                                <div class="w-full flex justify-between items-start mb-2">
                                    <i class="mdi mdi-plus-circle text-gray2 opacity-50 mr-2 md:mr-0"></i>
                                    <span
                                        v-if="element.name"
                                        class="text-sm md:text-tiny text-gray1 w-8/12 tb:w-9/12 leading-tight"
                                        >{{ element.name }}</span
                                    >
                                    <span v-else class="text-tiny text-gray1 w-9/12">Anuncio</span>
                                    <div class="flex items-center">
                                        <i class="mdi mdi-calendar text-md text-casika mr-2"></i>
                                        <span class="xs-title text-gray1">{{ element.date | moment('DD/MM/YY') }}</span>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:data>
                                <div class="w-full flex justify-between items-center my-1">
                                    <Kpi v-for="(kpi, index) in element.kpis" :key="kpi.id">
                                        <template slot="kpi">
                                            <span :content="kpi.name" v-tippy="{ followCursor: true, arrow: false }">
                                                {{ kpi.name.slice(0, 3) }}.
                                            </span>
                                        </template>
                                        <template slot="value">
                                            <div
                                                v-if="campaigns_datatypes[index].datatype === 'percent'"
                                                class="xs-value font-medium text-white"
                                            >
                                                {{ kpi.value | numFormat('0,0.0a') }}%
                                            </div>
                                            <div
                                                v-else-if="campaigns_datatypes[index].datatype === 'currency'"
                                                class="xs-value font-medium text-white"
                                            >
                                                {{ kpi.value | numFormat('0,0.0a') }}€
                                            </div>
                                            <div
                                                v-else-if="campaigns_datatypes[index].datatype === 'decimals'"
                                                class="xs-value font-medium text-white"
                                            >
                                                {{ kpi.value | numFormat('0,0.0') }}
                                            </div>
                                            <div v-else class="xs-value font-medium text-white">
                                                {{ kpi.value | numFormat('0,0a') }}
                                            </div>
                                        </template>
                                    </Kpi>
                                </div>
                            </template>
                            <template v-slot:kpis>
                                <div class="w-full grid grid-cols-1 md:grid-cols-2 tb:grid-cols-4 gap-1">
                                    <Box v-for="(item, index) in element.general" :key="item.id">
                                        <template v-slot:content>
                                            <div
                                                :content="main_kpis[index].name"
                                                v-tippy="{ followCursor: true, arrow: false }"
                                                class="flex justify-center items-center text-gray2"
                                            >
                                                <i class="mdi mr-2 text-xl" :class="'mdi-' + main_kpis[index].icon"></i>
                                                <div
                                                    v-if="general_datatypes[index].datatype === 'percent'"
                                                    class="sm-value font-medium"
                                                >
                                                    {{ item.value | numFormat('0,0.0a') }} %
                                                </div>
                                                <div
                                                    v-else-if="general_datatypes[index].datatype === 'currency'"
                                                    class="sm-value font-medium"
                                                >
                                                    {{ item.value | numFormat('0,0.0a') }} €
                                                </div>
                                                <div v-else class="sm-value font-medium">
                                                    {{ item.value | numFormat('0,0') }}
                                                </div>
                                            </div>
                                        </template>
                                    </Box>
                                </div>
                            </template>
                        </NetworkAdd>
                    </div>
                </template>
            </div>
        </All>
    </div>
</template>

<script>
    import { All } from '@/api/components';
    import Loading from '@/components/elements/Loading.vue';
    import NetworkHeader from '@/components/NetworkHeader.vue';
    import NetworkKpi from '@/components/NetworkKpi.vue';
    import NetworkAdd from '@/components/NetworkAdd.vue';
    import ButtonSecondary from '@/components/elements/ButtonSecondary.vue';
    import ButtonTabs from '@/components/elements/ButtonTabs.vue';
    import Kpi from '@/components/Kpi.vue';
    import Box from '@/components/Box.vue';
    export default {
        name: 'MarketingNetworks',
        components: {
            All,
            Loading,
            NetworkHeader,
            NetworkKpi,
            NetworkAdd,
            ButtonSecondary,
            ButtonTabs,
            Kpi,
            Box,
        },
        props: {
            // data: {
            //     type: [Array, Object],
            //     required: true,
            //     default: () => ([])
            // },
            network: {
                type: [Array, Object],
                required: true,
                default: () => [],
            },
            period: {
                type: Object,
                required: false,
                default: {},
            },
            market: {
                type: String,
                required: false,
                default: 'Mercado',
            },
            marketId: {
                type: Number,
                required: false,
                default: 6,
            },
        },
        computed: {
            getNetwork() {
                if(this.add_type === 0) {
                    return this.data.data.campaigns
                } else if (this.add_type === 1) {
                    return this.data.data.groups
                } else {
                    return this.data.data.advertisements
                }
            },
        },
        data() {
            return {
                data: [],
                add_type: 0,
                secondary: false,
                tabs: [
                    {
                        id: 0,
                        tab: 'Campañas',
                    },
                    {
                        id: 1,
                        tab: 'Grupos',
                    },
                    {
                        id: 2,
                        tab: 'Anuncios',
                    },
                ],
                main_kpis: [
                    {
                        id: 0,
                        name: 'Ingresos',
                        icon: 'cash-check',
                    },
                    {
                        id: 1,
                        name: 'ROAS',
                        icon: 'cash-refund',
                    },
                    {
                        id: 2,
                        name: 'Transac.',
                        icon: 'arrow-left-right-bold',
                    },
                    {
                        id: 3,
                        name: 'Inversión',
                        icon: 'cash-multiple',
                    },
                ],

                campaigns_datatypes: [
                    {
                        id: 0,
                        name: 'Alcance',
                        datatype: 'absolute',
                    },
                    {
                        id: 1,
                        name: 'CPM',
                        datatype: 'currency',
                    },
                    {
                        id: 2,
                        name: 'CPC',
                        datatype: 'currency',
                    },
                    {
                        id: 3,
                        name: 'CTR',
                        datatype: 'percent',
                    },
                    {
                        id: 4,
                        name: 'Frec.',
                        datatype: 'decimals',
                    },
                    {
                        id: 5,
                        name: 'Sesiones',
                        datatype: 'absolute',
                    },
                    {
                        id: 6,
                        name: 'ROAS',
                        datatype: 'currency',
                    },
                    {
                        id: 7,
                        name: 'Inversión',
                        datatype: 'currency',
                    },
                ],
                general_datatypes: [
                    {
                        id: 0,
                        name: 'Ingresos',
                        datatype: 'currency',
                    },
                    {
                        id: 1,
                        name: 'ROAS',
                        datatype: 'currency',
                    },
                    {
                        id: 2,
                        name: 'Transaciones',
                        datatype: 'absolute',
                    },
                    {
                        id: 3,
                        name: 'Inversión',
                        datatype: 'currency',
                    },
                ],
                secondary_datatypes: [
                    {
                        id: 0,
                        name: 'Alcance',
                        datatype: 'absolute',
                    },
                    {
                        id: 1,
                        name: 'Sesiones',
                        datatype: 'absolute',
                    },
                    {
                        id: 2,
                        name: 'Tasa rebote',
                        datatype: 'percent',
                    },
                    {
                        id: 3,
                        name: 'CPC medio',
                        datatype: 'currency',
                    },
                    {
                        id: 4,
                        name: 'Ticket medio',
                        datatype: 'currency',
                    },
                    {
                        id: 5,
                        name: '% conversión',
                        datatype: 'percent',
                    },
                ],
            };
        },
        methods: {
            buttonAction() {
                this.secondary = !this.secondary;
            },
            selectedTab(tab) {
                this.add_type = tab;
            },
        },
        watch: {
            add_type(n, o) {
                this.add_type = n;
            },
        },
    };
</script>

<style></style>
