<template>
    <div class="w-full h-auto px-3 py-3">
        <HeaderMarketing />

        <template>
            <div class="grid grid-cols-12 grid-rows-full grid-flow-row gap-3 w-full h-full flex-1">
                <div class="module row-span-2 xl:row-span-1 col-span-12">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="computedOrdersQuery"
                        :data.sync="marketing"
                        v-slot="{ loading }"
                        :config="{ url: 'social-networks' }"
                    >
                        <Loading v-if="loading"></Loading>
                        <MarketingGeneral v-else :data="marketing.data" />
                    </All>
                </div>

                <div
                    v-for="network in networks"
                    :key="'network-' + network.id"
                    class="module row-span-3 col-span-12 md:col-span-6 xl:col-span-4 marketNetwork"
                >
                    <MarketingNetworks
                        :network="network"
                        :period="period"
                        :market="market.name"
                        :marketId="market.id"
                    />
                </div>
            </div>
        </template>

        <portal v-if="modal" to="modals">
            <transition name="fade">
                <div
                    class="fixed inset-0 bg-body bg-opacity-75 blur w-screen h-screen z-50 f-center"
                    @click.self="closeModal"
                >
                    <Modal>
                        <template v-slot:content>
                            <template v-if="filter == 'filter-date'">
                                <span class="md-title font-medium mt-2">Seleccionar fechas</span>
                                <div class="flex">
                                    <VueCtkDateTimePicker
                                        v-model="selectedDate"
                                        :id="'calendar'"
                                        :range="true"
                                        :dark="true"
                                        :persistent="true"
                                        :auto-close="true"
                                        :no-shortcuts="true"
                                        :no-header="true"
                                        :no-value-to-custom-elem="true"
                                        :only-date="true"
                                        :no-button="true"
                                        :position="'bottom'"
                                        button-color="#F6B18A"
                                        color="#F6B18A"
                                    >
                                        <input class="hidden" type="text" />
                                    </VueCtkDateTimePicker>
                                </div>
                                <Button @click="closeModal">Aplicar filtro</Button>
                            </template>

                            <template v-else>
                                <All
                                    action="Request"
                                    model="ApiRequest"
                                    :immediate="true"
                                    :sensitive="true"
                                    :data.sync="countries"
                                    :config="{ url: 'countries' }"
                                >
                                </All>

                                <span class="md-title font-medium my-2">Seleccionar mercado</span>
                                <div
                                    v-for="country in countries.data"
                                    :key="country.id"
                                    class="
                                        w-full
                                        bg-background
                                        px-2
                                        rounded-md
                                        flex
                                        items-center
                                        mb-2
                                        py-1
                                        text-tiny
                                        cursor-pointer
                                    "
                                    @click="filterMarket(country.id)"
                                >
                                    <div
                                        class="w-1 h-6 mr-2"
                                        :class="{
                                            'bg-casika': selectedMarket == country.id,
                                            'bg-gray2': selectedMarket != country.id,
                                        }"
                                    ></div>
                                    <span
                                        :class="{
                                            'text-casika': selectedMarket == country.id,
                                            'text-gray2': selectedMarket != country.id,
                                        }"
                                    >
                                        {{ country.name }}</span
                                    >
                                </div>
                                <Button @click="closeModal()">Aplicar filtro</Button>
                            </template>
                        </template>
                    </Modal>
                </div>
            </transition>
        </portal>

        <!-- <HeaderTv v-if="tvMode" @openModal="openModal" :modal="modal" /> -->
    </div>
</template>

<script>
    import { All } from '@/api/components';
    import { actions, state } from '@/store';
    import HeaderMarketing from '@/components/header/HeaderMarketing.vue';
    import Loading from '@/components/elements/Loading.vue';
    import ModuleTab from '@/components/elements/ModuleTab';
    import HeaderTv from '@/components/header/HeaderTv.vue';
    import MarketingGeneral from '@/components/module/MarketingGeneral.vue';
    import MarketingNetworks from '@/components/module/MarketingNetworks.vue';
    import Button from '@/components/elements/Button.vue';
    import Modal from '@/components/elements/Modal.vue';

    export default {
        name: 'MarketingPage',
        components: {
            All,
            HeaderMarketing,
            Loading,
            ModuleTab,
            HeaderTv,
            MarketingGeneral,
            MarketingNetworks,
            Button,
            Modal,
        },
        computed: {
            computedOrdersQuery() {
                return {
                    from: this.period.from,
                    to: this.period.to,
                    country: this.marketId,
                };
            },
            user() {
                return state.user;
            },
            tvMode() {
                return state.tvMode;
            },
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
        },
        data() {
            return {
                modal: false,
                countries: [],
                selectedDate: null,
                selectedMarket: '',
                marketing: {},
                facebook: [],
                instagram: [],
                adwords: [],
                shopping: [],

                networks: [
                    {
                        id: 'all',
                        name: 'Facebook',
                        platform: 'facebook',
                    },
                    // {
                    //     id: 'instagram',
                    //     name: 'Instagram',
                    //     platform: 'facebook',
                    // },
                    {
                        id: 'adwords',
                        name: 'Adds',
                        platform: 'adwords',
                    },
                    {
                        id: 'shopping',
                        name: 'Shopping',
                        platform: 'adwords',
                    },
                ],
                // period: {
                //     from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                //     to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                // }
            };
        },
        methods: {
            filterMarket(data) {
                this.selectedMarket = data;
                //console.log('this.selectedMarket :>> ', this.selectedMarket);
            },
            openModal(filter) {
                this.modal = !this.modal;
                this.filter = filter;
            },
            closeModal() {
                this.modal = !this.modal;
                actions.setMarket(this.selectedMarket);
                //console.log('closeModals ');
            },
        },
    };
</script>
<style>
    .calendar,
    .custom-button.is-dark,
    .year-month-selector {
        background-color: #202837 !important;
    }
    .datepicker-container.is-dark .datepicker-day .datepicker-today {
        background-color: #5d76dd59 !important;
    }
    #calendar-wrapper.date-time-picker {
        position: relative;
        width: 400px;
        height: 330px;
        top: -95%;
    }
    @media (min-width: 768px) {
        .marketNetwork {
            min-height: 70vh;
        }
    }
</style>
