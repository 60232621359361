<template>
    <div class="bg-module rounded-xl p-6 shadow-xl w-3/12 mx-auto h-110 flex flex-col items-center px-5">
        <slot name="content"></slot>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
    }
</script>